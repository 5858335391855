import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CopyIcon from "@material-ui/icons/FileCopy";
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  TextField,
  Link,
  Button,
  AccordionSummary,
  Typography as MuiTypography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useApp } from "../../AppProvider";
import useFetchData from "../../hooks/useFetchData";
import Panel from "../../components/panels/Panel";
import { copyToClipboard, dateFormatter } from "../../utils";
import Loader from "../../components/Loader";
import { Autocomplete } from "@material-ui/lab";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function EditBenchmarks() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [currentEditorState, setCurrentEditorState] = useState(null);
  const [selectedMeasureType, setSelectedMeasureType] = useState(null);

  const newDefaultEditorState = [
    {
      benchmark_ndx: null,
      measure_type_ndx: null,
      benchmark_scale: 0,
      low_bound: -0.001,
      high_bound: 0,
      notes: "",
      low_is_bad: false,
      unit_ndx: null,
    },
    {
      benchmark_ndx: null,
      measure_type_ndx: null,
      benchmark_scale: 1,
      low_bound: 0,
      high_bound: 30,
      notes: "",
      low_is_bad: false,
      unit_ndx: null,
    },
    {
      benchmark_ndx: null,
      measure_type_ndx: null,
      benchmark_scale: 2,
      low_bound: 30,
      high_bound: 60,
      notes: "",
      low_is_bad: false,
      unit_ndx: null,
    },
    {
      benchmark_ndx: null,
      measure_type_ndx: null,
      benchmark_scale: 3,
      low_bound: 60,
      high_bound: 100,
      notes: "",
      low_is_bad: false,
      unit_ndx: null,
    },
    {
      benchmark_ndx: null,
      measure_type_ndx: null,
      benchmark_scale: 4,
      low_bound: 100,
      high_bound: 999999,
      notes: "",
      low_is_bad: false,
      unit_ndx: null,
    },
  ];

  const handleUpdateEditorState = (index, name, value) => {
    setCurrentEditorState((prevState) => {
      let newFilterValues = [...prevState];
      newFilterValues[index][name] = value;
      return newFilterValues;
    });
  };

  const [MeasureTypes] = useFetchData("list-measure-types", [], true);
  const measureTypeLookup = useMemo(() => {
    let converted = {};
    if (MeasureTypes?.length > 0) {
      MeasureTypes.forEach((item) => {
        converted[item.measure_type_ndx] = item.measure_type_desc;
      });
    }
    return converted;
  }, [MeasureTypes]);

  const {
    data,
    // isFetching: isLoading,
    // error,
    refetch,
  } = useQuery(
    ["benchmarks-by-reach"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/benchmarks-by-reach`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const measureTypesWithBenchmarks = useMemo(() => {
    if (data?.length > 0) {
      return data
        .filter((item) => item.benchmark_scale === 0)
        .map((item) => item.measure_type_ndx);
    }
  }, [data]);

  const editTableColumns = [
    {
      title: "Measure Type",
      field: "measure_type_ndx",
      lookup: measureTypeLookup,
    },
    {
      title: "Notes",
      field: "notes",
    },
    {
      title: "Low is Bad",
      field: "low_is_bad",
      type: "boolean",
    },
  ];

  const handleSync = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/benchmarks-by-reach/sync`,
          {},
          { headers }
        );
        doToast(
          "success",
          "Publishing new benchmarks. This process may take several minutes to complete."
        );
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleSubmit = () => {
    if (currentEditorState[0].measure_type_ndx) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const finalEditorState = currentEditorState.map((item) => {
          return {
            ...item,
            unit_ndx: MeasureTypes.find(
              (measureType) =>
                measureType.measure_type_ndx ===
                selectedMeasureType.measure_type_ndx
            ).unit_ndx,
            measure_type_ndx: selectedMeasureType.measure_type_ndx,
          };
        });

        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/benchmarks-by-reach`,
          finalEditorState,
          { headers }
        );
        refetch().then();
        doToast("success", "New entry was saved to the database");
        //clear the currentRow and removes the form
        setCurrentEditorState(null);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = () => {
    return (async () => {
      try {
        if (currentEditorState) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.patch(
            `${process.env.REACT_APP_ENDPOINT}/api/benchmarks-by-reach`,
            currentEditorState,
            { headers }
          );
          refetch().then();
          doToast("success", "New data was updated to the database");
          //clear the currentRow and removes the form
          setCurrentEditorState(null);
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/benchmarks-by-reach/${oldData.measure_type_ndx}`,
            { headers }
          );
          refetch().then();
          doToast("success", "This entry was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const disableSubmit =
    !currentEditorState ||
    (!selectedMeasureType && !currentEditorState[0]?.measure_type_ndx) ||
    currentEditorState[0]?.low_bound === null ||
    currentEditorState[0]?.high_bound === null ||
    currentEditorState[1]?.low_bound === null ||
    currentEditorState[1]?.high_bound === null ||
    currentEditorState[2]?.low_bound === null ||
    currentEditorState[2]?.high_bound === null ||
    currentEditorState[3]?.low_bound === null ||
    currentEditorState[3]?.high_bound === null ||
    currentEditorState[4]?.low_bound === null ||
    currentEditorState[4]?.high_bound === null;

  const BOOLEAN_OPTIONS = [
    {
      value: true,
      option: "Yes",
    },
    {
      value: false,
      option: "No",
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Edit Benchmarks" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Benchmarks
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Edit Benchmarks</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Button
        style={{ width: "100%", height: "44px", marginBottom: "12px" }}
        type="submit"
        size="small"
        color="secondary"
        variant="contained"
        onClick={handleSync}
      >
        Publish New Benchmarks
      </Button>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Edit Benchmarks
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  {data ? (
                    <MaterialTable
                      id="Edit Benchmarks"
                      title={`Edit Benchmarks ${dateFormatter(
                        new Date(),
                        "MM/DD/YYYY, h:mm A"
                      )}`}
                      columns={editTableColumns}
                      data={data.filter((item) => item.benchmark_scale === 0)}
                      editable={{
                        onRowDelete: handleDelete,
                      }}
                      localization={{
                        toolbar: { searchPlaceholder: "Search Measure Types" },
                      }}
                      components={{
                        Container: (props) => <div {...props} />,
                      }}
                      actions={[
                        {
                          icon: CopyIcon,
                          tooltip: "Copy Data",
                          isFreeAction: true,
                          onClick: () => {
                            try {
                              copyToClipboard(data, editTableColumns, () =>
                                doToast(
                                  "success",
                                  "Data was copied to your clipboard."
                                )
                              );
                            } catch (error) {
                              const message =
                                error?.message ?? "Something went wrong";
                              doToast("error", message);
                            }
                          },
                        },
                        () => ({
                          icon: "edit",
                          tooltip: "Edit",
                          onClick: (event, rowData) => {
                            setCurrentEditorState(
                              data.filter(
                                (item) =>
                                  item.measure_type_ndx ===
                                  rowData?.measure_type_ndx
                              )
                            );
                          },
                        }),
                        {
                          icon: "add_box",
                          tooltip: "Add",
                          isFreeAction: true,
                          onClick: () => {
                            setCurrentEditorState(newDefaultEditorState);
                            setSelectedMeasureType(null);
                          },
                        },
                      ]}
                      options={{
                        emptyRowsWhenPaging: false,
                        showTitle: false,
                        columnsButton: true,
                        exportButton: true,
                        exportAllData: true,
                        addRowPosition: "first",
                        pageSize: 200,
                        pageSizeOptions: [10, 30, 50, 100, 200],
                        padding: "dense",
                        searchFieldAlignment: "left",
                        maxBodyHeight: "400px",
                      }}
                    />
                  ) : (
                    <Loader />
                  )}
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>

      {currentEditorState && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="table-content"
                id="table-header"
              >
                <Typography variant="h4" ml={2}>
                  {!currentEditorState[0].measure_type_ndx
                    ? "New Benchmark"
                    : `Editing Benchmark for "${
                        MeasureTypes.find(
                          (item) =>
                            currentEditorState[0].measure_type_ndx ===
                            item.measure_type_ndx
                        )?.measure_type_desc
                      }"`}
                </Typography>
              </AccordionSummary>
              <Panel>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    {MeasureTypes.length > 0 &&
                      !currentEditorState[0]?.measure_type_ndx && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            position: "relative",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Select a Measure Type that does not have a Benchmark
                          </Typography>
                          <Autocomplete
                            style={{ marginTop: "5px" }}
                            mt={3}
                            id="well"
                            options={MeasureTypes.filter(
                              (item) =>
                                !measureTypesWithBenchmarks.includes(
                                  item.measure_type_ndx
                                )
                            )}
                            getOptionLabel={(option) =>
                              option.measure_type_desc
                            }
                            onChange={(e, value) => {
                              setSelectedMeasureType(value);
                            }}
                            value={selectedMeasureType}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "100%" }}
                                {...params}
                                variant="outlined"
                                label="Measure Type:"
                              />
                            )}
                          />
                        </Grid>
                      )}
                    {currentEditorState.map((item, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Benchmark {index}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            position: "relative",
                          }}
                        >
                          <TextField
                            type="number"
                            variant="outlined"
                            label="Low Bound"
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                handleUpdateEditorState(
                                  index,
                                  "low_bound",
                                  null
                                );
                              } else {
                                handleUpdateEditorState(
                                  index,
                                  "low_bound",
                                  +e.target.value
                                );
                              }
                            }}
                            value={currentEditorState[index]?.low_bound ?? ""}
                            InputProps={{
                              inputProps: {
                                onKeyPress: (e) => {
                                  if (e.key === "e") {
                                    e.preventDefault();
                                  }
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            position: "relative",
                          }}
                        >
                          <TextField
                            type="number"
                            variant="outlined"
                            label="High Bound"
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                handleUpdateEditorState(
                                  index,
                                  "high_bound",
                                  null
                                );
                              } else {
                                handleUpdateEditorState(
                                  index,
                                  "high_bound",
                                  +e.target.value
                                );
                              }
                            }}
                            value={currentEditorState[index]?.high_bound ?? ""}
                            InputProps={{
                              inputProps: {
                                onKeyPress: (e) => {
                                  if (e.key === "e") {
                                    e.preventDefault();
                                  }
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            position: "relative",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            label="Notes"
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              handleUpdateEditorState(
                                index,
                                "notes",
                                e.target.value
                              )
                            }
                            value={currentEditorState[index]?.notes ?? ""}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            position: "relative",
                          }}
                        >
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            <InputLabel id="enabled">Low is Bad</InputLabel>
                            <Select
                              labelId="low-is-bad-label"
                              id="low-is-bad"
                              label="Low is Bad"
                              value={currentEditorState[0]?.low_is_bad ?? ""}
                              onChange={(e) => {
                                currentEditorState.map((item, index) =>
                                  handleUpdateEditorState(
                                    index,
                                    "low_is_bad",
                                    e.target.value
                                  )
                                );
                              }}
                            >
                              {BOOLEAN_OPTIONS.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                  {item.option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </React.Fragment>
                    ))}

                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{
                        position: "relative",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setCurrentEditorState(null);
                          setSelectedMeasureType(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        position: "relative",
                      }}
                    >
                      <Button
                        style={{ width: "100%", height: "44px" }}
                        type="submit"
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={disableSubmit}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Panel>
            </Accordion>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default EditBenchmarks;
