import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FilterControl from "../filters/filterControl";
import Filter from "../filters/filter";
import WellStylesControl from "../controls/wellStylesControl";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import useLayerStyles from "../hooks/useLayerStyles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useTheme from "@material-ui/core/styles/useTheme";
import { RotateLeft } from "@material-ui/icons";

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

const FiltersBar = ({
  filterValues,
  handleFilterValues,
  handleSelectAll,
  handleSelectNone,
  updateLayerStyles,
  graphModeVisible,
  handleResetAll,
}) => {
  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
  });
  const theme = useTheme();

  useEffect(() => {
    if (graphModeVisible) {
      handleActiveStyle("default");
    }
  }, [graphModeVisible]); //eslint-disable-line

  const splitButtonOptions = [
    filterValues?.locationTypes,
    filterValues?.streamSegments,
    filterValues?.organizations,
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const getMoreFiltersCount = (filterValues) => {
    const keys = [
      "moreThanA",
      "moreThanB",
      "moreThanC",
      "dataRecentA",
      "dataRecentB",
    ];
    return keys.filter((key) => filterValues[key].value).length;
  };

  return (
    <>
      <FiltersSection>
        <FiltersContainer>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            <FilterControl
              width="200px"
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
              appliedCount={splitButtonOptions[selectedIndex].value?.length}
              label={splitButtonOptions[selectedIndex].label}
              countText={`${splitButtonOptions[selectedIndex].value?.length}/${splitButtonOptions[selectedIndex].options?.length}`}
            >
              <Filter
                label={splitButtonOptions[selectedIndex].label}
                name={splitButtonOptions[selectedIndex].name}
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={splitButtonOptions[selectedIndex].options}
                type={splitButtonOptions[selectedIndex].type}
                value={splitButtonOptions[selectedIndex].value}
              />
            </FilterControl>

            <Button
              disableElevation
              color="primary"
              variant="outlined"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
            <Tooltip title="Reset All Filters" placement="bottom">
              <Button
                disableElevation
                color="primary"
                variant="outlined"
                onClick={handleResetAll}
              >
                <RotateLeft />
              </Button>
            </Tooltip>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 4 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {splitButtonOptions.map((option, index) => (
                        <MenuItem
                          key={option.label}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {`${option.label} - ${splitButtonOptions[index].value?.length}/${splitButtonOptions[index].options?.length}`}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </FiltersContainer>
      </FiltersSection>

      <FiltersContainer>
        <FiltersSection>
          <FilterControl
            width="100px"
            appliedCount={getMoreFiltersCount(filterValues)}
            label="Toggles"
          >
            <Box display="flex" flexDirection="column">
              <Filter
                label="More than 1 Result"
                name="moreThanA"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.moreThanA?.value}
              />
              <Filter
                label="More than 3 Results"
                name="moreThanB"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.moreThanB?.value}
              />
              <Filter
                label="More than 10 Results"
                name="moreThanC"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.moreThanC?.value}
              />
              <Filter
                label="Collected within Last 10 Years"
                name="dataRecentA"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.dataRecentA?.value}
              />
              <Filter
                label="Collected within Last 5 Years"
                name="dataRecentB"
                onChange={handleFilterValues}
                type="boolean"
                value={filterValues?.dataRecentB?.value}
              />
            </Box>
          </FilterControl>
        </FiltersSection>
      </FiltersContainer>

      {!graphModeVisible && (
        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="250px"
              label={`Color wells by ${activeStyle.name}`}
            >
              <Typography variant="subtitle1" gutterBottom>
                Color wells by
              </Typography>
              <WellStylesControl
                label="Color wells by"
                name="wellStyles"
                onChange={handleActiveStyle}
                options={styleOptions}
                value={activeStyle.id}
              />
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>
      )}
    </>
  );
};

export default FiltersBar;
