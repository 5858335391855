import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import useFetchData from "../../../../hooks/useFetchData";
import { getUniqueListBy, groupByValue } from "../../../../utils";
import { titleize } from "inflected";
import { useAuth0 } from "@auth0/auth0-react";

const useGraphMode = ({
  map,
  updateLayerFilters,
  updateLayerStyles,
  layers,
  lastLocationIdClicked,
  setLastLocationIdClicked,
  setDataVizVisible,
  graphModeVisible,
  setGraphModeVisible,
  setFilterValues,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [legendVisible, setLegendVisible] = useState(true);
  const [lastLocationId, setLastLocationId] = useState(null);
  const [inputValue, setInputValue] = useState(0);

  const initFilterValues = {
    periodOfRecord: "full",
    analysis: "benchmark_scale_median",
    parameterGroups: [
      "Bacteria",
      "Metals",
      "Nutrient",
      "Other",
      "Physical",
      "NISP Parameters",
    ],
    parameters: ["E. Coli"],
    recordCount: 0,
  };
  const [filterValuesGraphMode, setFilterValuesGraphMode] =
    useState(initFilterValues);

  const periodOfRecords = [
    {
      value: "short",
      label: "Recent",
    },
    {
      value: "medium",
      label: "Last 10 Years",
    },
    {
      value: "full",
      label: "Full Period",
    },
  ];
  const analysisTypes = [
    {
      value: "benchmark_scale_median",
      label: "Median",
    },
    {
      value: "benchmark_scale_pctile85",
      label: "85th Percentile",
    },
  ];
  const [parameterGroups, isParameterGroupsLoading] = useFetchData(
    "list-parameter-groups-graph-mode",
    [],
    false
  );

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters, isFetching: isParametersFetching } = useQuery(
    [
      "list-parameters-graph-mode",
      filterValuesGraphMode.periodOfRecord,
      filterValuesGraphMode.parameterGroups,
      isParameterGroupsLoading,
    ],
    async () => {
      if (!isParameterGroupsLoading) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-parameters-graph-mode`,
            {
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
              parameterGroups: filterValuesGraphMode.parameterGroups.map(
                (parameterGroup) => getParameterGroupIndexByName(parameterGroup)
              ),
            }
          );
          return getUniqueListBy(data, "parameter_ndx");
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const [benchmarkScaleColors] = useFetchData(
    "list-benchmark-scale-colors",
    [],
    false
  );
  const getHexColorForScore = (score) => {
    return (
      benchmarkScaleColors.find((x) => x.benchmark_scale === score)
        .symbol_color || "black"
    );
  };

  const graphModeBenchmarkColorsDefaults = [
    { name: `Above secondary benchmark`, color: `red` },
    { name: `Above benchmark`, color: `orange` },
    { name: `Approaching benchmark`, color: `yellow` },
    { name: `Below benchmark`, color: `MediumSeaGreen` },
    { name: `Below benchmark`, color: `PaleTurquoise` },
    { name: `No benchmarks available`, color: `cornflowerblue` },
  ];
  const [graphModeBenchmarkColors, setGraphModeBenchmarkColors] = useState(
    graphModeBenchmarkColorsDefaults
  );
  useEffect(() => {
    if (benchmarkScaleColors) {
      setGraphModeBenchmarkColors([
        {
          name: `Above secondary benchmark`,
          color: benchmarkScaleColors[5]?.symbol_color ?? `red`,
        },
        {
          name: `Above benchmark`,
          color: benchmarkScaleColors[4]?.symbol_color ?? `orange`,
        },
        {
          name: `Approaching benchmark`,
          color: benchmarkScaleColors[3]?.symbol_color ?? `yellow`,
        },
        {
          name: `Below benchmark`,
          color: benchmarkScaleColors[2]?.symbol_color ?? `MediumSeaGreen`,
        },
        {
          name: `Below detection limits`,
          color: benchmarkScaleColors[1]?.symbol_color ?? `PaleTurquoise`,
        },
        {
          name: `No benchmarks available`,
          color: benchmarkScaleColors[0]?.symbol_color ?? `cornflowerblue`,
        },
      ]);
    }
  }, [benchmarkScaleColors]);

  const [graphModePopupVisible, setGraphModePopupVisible] = useState(true);
  const [graphModeLayersVisible, setGraphModeLayersVisible] = useState(true);
  const handleGraphModeLayersToggleClick = () => {
    if (graphModeLayersVisible) {
      layers.forEach((layer) => {
        if (
          ["greeley-locations-circle", "greeley-locations-symbol"].includes(
            layer.id
          )
        ) {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    } else {
      layers.forEach((layer) => {
        if (layer?.layout?.visibility === "visible") {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    }
    setGraphModeLayersVisible(!graphModeLayersVisible);
  };

  const handleGraphModeClick = () => {
    if (!graphModeVisible) {
      // layers.forEach((layer) => {
      //   if (
      //     ["greeley-locations-circle", "greeley-locations-symbol"].includes(
      //       layer.id
      //     )
      //   ) {
      //     map.setLayoutProperty(
      //       layer?.lreProperties?.name || layer.id,
      //       "visibility",
      //       "visible"
      //     );
      //   } else {
      //     map.setLayoutProperty(
      //       layer?.lreProperties?.name || layer.id,
      //       "visibility",
      //       "none"
      //     );
      //   }
      // });
      setDataVizVisible(true);
      // map.setFilter("greeley-locations-circle", null);
      // map.setFilter("greeley-locations-symbol", null);
    } else {
      // map.setFilter("greeley-locations-circle", null);
      // map.setFilter("greeley-locations-symbol", null);

      // updateLayerFilters(filterValues);

      setFilterValues((prevState) => {
        const newState = {
          ...prevState,
          graphMode: {
            ...prevState["graphModeReference"],
          },
        };
        updateLayerFilters(newState);
        return newState;
      });
      updateLayerStyles({
        id: "default",
        layerId: "greeley-locations-circle",
        layerFieldName: "",
        name: "Default",
        paint: {
          "circle-color": "#1e8dd2",
          "circle-stroke-width": 2,
          "circle-stroke-color": "black",
        },
      });
      setDataVizVisible(false);

      layers.forEach((layer) => {
        if (layer?.layout?.visibility === "visible") {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "visible"
          );
        } else {
          map.setLayoutProperty(
            layer?.lreProperties?.name || layer.id,
            "visibility",
            "none"
          );
        }
      });
    }
    // handleFilterValuesGraphMode("recordCount", 0);
    setInputValue(0);
    setGraphModePopupVisible(true);
    map.fire("closeAllPopups");
    setLastLocationIdClicked(null);
    setLastLocationId(null);
    setGraphModeVisible(!graphModeVisible);
    setGraphModeLayersVisible(true);
  };

  useEffect(() => {
    if (!isParametersFetching && parameters?.length && !hasParametersLoaded) {
      setHasParametersLoaded(true);
      onSelectAllParameters();
    }
  }, [isParametersFetching, parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          parameters: cleanParams(filterValuesGraphMode.parameters),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          parameterGroups: cleanParamGroups(
            filterValuesGraphMode.parameterGroups
          ),
        };
      });
    }
  }, [parameterGroups]); //eslint-disable-line

  const [hasGraphDataLoaded, setHasGraphDataLoaded] = useState(false);
  const [isAnalyticsTableDataLoading, setIsAnalyticsTableDataLoading] =
    useState(false);
  const { data } = useQuery(
    [
      "locations-map-graph-mode",
      filterValuesGraphMode.periodOfRecord,
      filterValuesGraphMode.recordCount,
      filterValuesGraphMode.parameters,
      hasParametersLoaded,
      isAuthenticated,
    ],
    async () => {
      if (hasParametersLoaded) {
        setIsAnalyticsTableDataLoading(true);
        let headers = {};
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          headers = { Authorization: `Bearer ${token}` };
        }
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/locations-map-graph-mode`,
            {
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
              recordCount: filterValuesGraphMode.recordCount,
              parameters: filterValuesGraphMode.parameters.map((parameter) =>
                getParameterIndexByName(parameter)
              ),
            },
            { headers }
          );

          setHasGraphDataLoaded(true);
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (graphModeVisible) {
      setIsAnalyticsTableDataLoading(false);
      recolorPointsForLayers(data);
      fetchAnalyticsTableForLocation();
      fetchAnalyticsTimeSeriesForLocation();
    }
  }, [graphModeVisible, data]); //eslint-disable-line

  useEffect(() => {
    if (graphModeVisible) {
      recolorPointsForLayers(data);
    }
  }, [filterValuesGraphMode.analysis]); //eslint-disable-line

  const recolorPointsForLayers = (data = null) => {
    const layerIds = ["greeley-locations-circle"];

    // sort by location_index ascending
    data.sort((a, b) => (a.ndx > b.ndx ? 1 : b.ndx > a.ndx ? -1 : 0));

    const colorData = [];
    const locationValues = {};

    data.forEach((row) => {
      // set a default score
      if (
        typeof locationValues[row.ndx] === "undefined" &&
        locationValues[row.ndx] !== null
      ) {
        locationValues[row.ndx] = -1;
      }

      if (
        filterValuesGraphMode.analysis === "benchmark_scale_pctile85" &&
        filterValuesGraphMode.analysis !== null
      ) {
        if (row.benchmark_scale_pctile85 > locationValues[row.ndx]) {
          locationValues[row.ndx] = row.benchmark_scale_pctile85;
        }
      } else {
        if (
          row.benchmark_scale_median > locationValues[row.ndx] &&
          filterValuesGraphMode.analysis !== null
        ) {
          locationValues[row.ndx] = row.benchmark_scale_median;
        }
      }
    });

    for (const [loc_id, score] of Object.entries(locationValues)) {
      colorData.push(parseInt(loc_id));
      colorData.push(getHexColorForScore(score));
    }

    setFilterValues((prevState) => {
      const newState = {
        ...prevState,
        graphMode: {
          ...prevState["graphMode"],
          value: Object.keys(locationValues).map((x) => parseInt(x)).length
            ? Object.keys(locationValues).map((x) => parseInt(x))
            : "",
        },
      };
      updateLayerFilters(newState);
      return newState;
    });

    layerIds.forEach((id) => {
      if (colorData.length) {
        map.setPaintProperty(id, "circle-stroke-width", 2);
        map.setPaintProperty(id, "circle-stroke-color", "black");
        map.setPaintProperty(id, "circle-opacity", 1);
        map.setPaintProperty(id, "circle-stroke-opacity", 1);
        map.setPaintProperty(id, "circle-color", [
          "interpolate",
          ["linear"],
          ["get", "ndx"],
          ...colorData,
        ]);
      }
    });
  };

  const getParameterGroupIndexByName = (name) => {
    let parameterGroup = parameterGroups?.find(
      (x) => x.parameter_group_name === name
    );
    return parameterGroup.parameter_group_ndx;
  };

  const getParameterIndexByName = (name) => {
    let parameter = parameters.find((x) => x.parameter_abbrev === name);
    return parameter?.parameter_ndx || null;
  };

  const cleanParams = (params) => {
    const newParams = [];

    parameters.forEach((x) => {
      if (params.indexOf(x.parameter_abbrev) !== -1) {
        newParams.push(x.parameter_abbrev);
      }
    });

    return newParams;
  };

  const cleanParamGroups = (params) => {
    const newParams = [];

    parameterGroups
      .filter((item) =>
        item.stats_period.includes(filterValuesGraphMode.periodOfRecord)
      )
      .forEach((x) => {
        if (params.indexOf(x.parameter_group_name) !== -1) {
          newParams.push(x.parameter_group_name);
        }
      });

    return newParams;
  };

  const handleFilterValuesGraphMode = (name, value) => {
    if (!["periodOfRecord", "analysis", "recordCount"].includes(name)) {
      setFilterValuesGraphMode((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(value);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else {
      setFilterValuesGraphMode((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const onSelectAllParameters = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameters: parameters.map((x) => x.parameter_abbrev),
      };
    });
  };

  const onSelectNoneParameters = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameters: [],
      };
    });
  };

  const onSelectAllParameterGroups = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameterGroups: parameterGroups.map((x) => x.parameter_group_name),
      };
    });
  };

  const onSelectNoneParameterGroups = () => {
    setFilterValuesGraphMode((prevState) => {
      return {
        ...prevState,
        parameterGroups: [],
      };
    });
  };

  useEffect(() => {
    if (graphModeVisible !== null) {
      onSelectAllParameters();
      onSelectAllParameterGroups();
    }
  }, [graphModeVisible]); //eslint-disable-line

  const [analyticsResults, setAnalyticsResults] = useState(null);
  const [timeSeriesResults, setTimeSeriesResults] = useState(null);

  function fetchAnalyticsTableForLocation(location_index) {
    if (graphModeVisible) {
      if (!location_index) location_index = lastLocationId;
      if (!location_index) return;
      setAnalyticsResults(
        data.filter(
          (item) =>
            item.stats_period === filterValuesGraphMode.periodOfRecord &&
            item.ndx === location_index
        )
      );
    }
  }

  const [isTimeSeriesResultsLoading, setIsTimeSeriesResultsLoading] =
    useState(false);
  function fetchAnalyticsTimeSeriesForLocation(location_index) {
    setIsTimeSeriesResultsLoading(true);
    if (graphModeVisible) {
      if (!location_index) location_index = lastLocationId;
      if (!location_index) return;

      async function send() {
        try {
          const { data: line } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-table-for-map-display/${location_index}`,
            {
              parameters: cleanParams(filterValuesGraphMode.parameters).map(
                (x) => getParameterIndexByName(x)
              ),
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
            }
          );

          const groupedLineArray = groupByValue(line, "parameter");

          const { data: bar } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-annual-table-for-map-display/${location_index}`,
            {
              parameters: cleanParams(filterValuesGraphMode.parameters).map(
                (x) => getParameterIndexByName(x)
              ),
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
            }
          );

          const groupedBarArray = groupByValue(bar, "parameter");

          // console.log("line", groupedLineArray);
          // console.log("bar", groupedBarArray);

          setTimeSeriesResults({
            line: groupedLineArray,
            bar: groupedBarArray,
          });
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }

      send().then(() => {});
    }
  }

  useEffect(() => {
    setIsTimeSeriesResultsLoading(false);
  }, [timeSeriesResults]);

  useEffect(() => {
    setLastLocationId(lastLocationIdClicked);
    fetchAnalyticsTableForLocation(lastLocationIdClicked);
    fetchAnalyticsTimeSeriesForLocation(lastLocationIdClicked);
  }, [lastLocationIdClicked]); //eslint-disable-line

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = (index) => {
    if (![2, 3, 4, 5].includes(index)) return;
    setIsExportLoading(true);
    async function send() {
      try {
        if (index === 2) {
          let { data: timeseriesData } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-table-for-map-display`,
            {
              parameters: cleanParams(filterValuesGraphMode.parameters).map(
                (x) => getParameterIndexByName(x)
              ),
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
              indexes: [...new Set(data.map((item) => item.ndx))],
            }
          );

          const timeseriesDataCsvString = [
            [
              `"Results for parameters: ${filterValuesGraphMode.parameters.join(
                ", "
              )}"`,
            ],
            [
              `"Time Series Data for the ${titleize(
                filterValuesGraphMode.periodOfRecord
              )} Period"`,
            ],
            [
              "Location ID",
              "Location Name",
              "Parameter",
              "Parameter Abbrev.",
              "Parameter Groups",
              "Activity Date",
              "Data Value",
              "Units",
              // "Source",
              "Organizations",
            ],
            ...timeseriesData.map((item) => [
              item.location_id,
              `"${item.location_name}"`,
              `"${item.parameter}"`,
              `"${item.param_abbrev}"`,
              `"${item.parameter_group_array.join(", ")}"`,
              item.collect_date,
              item.result,
              item.units,
              // item.source,
              `"${item.organization}"`,
            ]),
          ]
            .map((e) => e.join(","))
            .join("\n");

          const a = document.createElement("a");
          a.href =
            "data:attachment/csv," +
            encodeURIComponent(timeseriesDataCsvString);
          a.target = "_blank";
          a.download = `Time Series Data for the ${titleize(
            filterValuesGraphMode.periodOfRecord
          )} Period.csv`;
          document.body.appendChild(a);
          a.click();
          // return csvString;
        }

        if (index === 3) {
          let tableData = [...data];
          const tableDataCsvString = [
            [
              `"Results for parameters: ${filterValuesGraphMode.parameters.join(
                ", "
              )}"`,
            ],
            [
              `"Stats & Benchmarks Data for the ${titleize(
                filterValuesGraphMode.periodOfRecord
              )} Period"`,
            ],
            [
              "Location ID",
              "Location Name",
              "Parameter",
              "Parameter Abbrev.",
              "Parameter Groups",
              "Units",
              "Count of Results (Statistics)",
              "85th or 15th percentile",
              "Benchmark Classification: 85th/15th",
              "Median",
              "Benchamrk Classification: Median",
              "Analysis Period (Statistics)",
              "From",
              "To",
              "Benchmark 0",
              "Benchmark 1",
              "Benchmark 2",
              "Benchmark 3",
              "Benchmark 4",
              "Trend (all data)",
              "Organizations",
            ],
            ...tableData.map((item) => [
              item.location_id,
              `"${item.location_name}"`,
              `"${item.parameter}"`,
              `"${item.parameter_abbrev}"`,
              `"${item.parameter_group_array.join(", ")}"`,
              item.units,
              item.recordcount,
              item.pctile85,
              item.benchmark_scale_pctile85,
              item.median,
              item.benchmark_scale_median,
              item.stats_period,
              item.por_start,
              item.por_end,
              item.bmk_line0,
              item.bmk_line1,
              item.bmk_line2,
              item.bmk_line3,
              item.bmk_line4,
              item.trend,
              `"${item.organization_name}"`,
            ]),
          ]
            .map((e) => e.join(","))
            .join("\n");

          const a = document.createElement("a");
          a.href =
            "data:attachment/csv," + encodeURIComponent(tableDataCsvString);
          a.target = "_blank";
          a.download = `Stats & Benchmarks Data for the ${titleize(
            filterValuesGraphMode.periodOfRecord
          )} Period.csv`;
          document.body.appendChild(a);
          a.click();
        }

        if (index === 4) {
          let { data: timeseriesData } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/ts-daily-table-for-map-display`,
            {
              parameters: [
                739, 23, 744, 721, 5, 98, 112, 6, 25, 1, 9, 102, 139, 10, 11,
                104, 125, 53, 21, 20, 62, 110, 13, 105, 137, 58, 32, 719, 86,
                723, 34, 37, 19, 22, 16, 722, 33, 17, 729, 30,
              ],
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
              indexes: [
                20, 609, 783, 773, 768, 777, 776, 775, 774, 769, 771, 767, 638,
                641, 799, 647, 69, 19, 36, 37, 77, 67, 800, 65, 23, 21, 68, 70,
                78, 613, 754, 25, 24, 693, 724, 752, 73, 766, 759, 74, 801, 722,
                26, 723,
              ],
            }
          );

          const timeseriesDataCsvString = [
            [
              `"Results for NISP parameters: Ammonia as N, Dissolved, Ammonia as N, Total, Ammonia as NH3, Total, Ammonia as NH4, Total, Arsenic, Dissolved, Arsenic, TD, Arsenic, Total, Arsenic, Total Recoverable, E. Coli, Flow, Iron, Dissolved, Iron, TD, Iron, Total, Iron, Total Recoverable, Manganese, Dissolved, Manganese, TD, Manganese, Total, Manganese, Total Recoverable, Nitrate as N, Nitrite as N, Orthophosphate, Total, Particulate Nitrogen, Selenium, Dissolved, Selenium, TD, Selenium, Total, Selenium, Total Recoverable, Soluble Reactive Phosphorus, Total Dissolved Kjeldahl Nitrogen as N, Total Dissolved N, Total Dissolved Nitrogen as N, Total Dissolved Phosphorus, Total Inorganic Nitrogen, Total Kjeldahl Nitrogen as N, Total Nitrate + Nitrite as N, Total Nitrogen, Total Nitrogen as NO3, Total Particulate Phosphorus, Total Phosphorus, Total Phosphorus as PO4, Water Temperature"`,
            ],
            [
              `"Results for NISP locations: 000027, 5309, 022FERN, 055WPCF, 145FSPUR, 215CHC, 225SGAGE, 2D-ARC3, 2D-GRE4, 325PFOS, 350LCR5, AG-EAS, AG-WES, CO0020320-D/S, CO0020320-EFF, CO0020478-D/S, CO0048860-EFF, CO0048860-U/S, ED-PRU, ED-PRU2, FC-0.8, GREELEYEFF, PR-16, PR-19.6, PR-2.9, PR-32.7, PR-35.3, PR-38.2, PR-4.9, PR-5.6, PR-7.5, PR-BGW, PR-TGW, TMDL-CP06, TMDL-CP09, TMDL-CP11, TMDL-CP12, TMDL-CP13, TMDL-CP14, TMDL-CP15, TMDL-CP16, TMDL-CP17, TMDL-CP18, TMDL-CP19"`,
            ],
            [
              `"Time Series Data for the ${titleize(
                filterValuesGraphMode.periodOfRecord
              )} Period"`,
            ],
            [
              "Location ID",
              "Location Name",
              "Parameter",
              "Parameter Abbrev.",
              "Parameter Groups",
              "Activity Date",
              "Data Value",
              "Units",
              "Organizations",
            ],
            ...timeseriesData.map((item) => [
              item.location_id,
              `"${item.location_name}"`,
              `"${item.parameter}"`,
              `"${item.param_abbrev}"`,
              `"${item.parameter_group_array.join(", ")}"`,
              item.collect_date,
              item.result,
              item.units,
              `"${item.organization}"`,
            ]),
          ]
            .map((e) => e.join(","))
            .join("\n");

          const a = document.createElement("a");
          a.href =
            "data:attachment/csv," +
            encodeURIComponent(timeseriesDataCsvString);
          a.target = "_blank";
          a.download = `NISP Time Series Data for the ${titleize(
            filterValuesGraphMode.periodOfRecord
          )} Period.csv`;
          document.body.appendChild(a);
          a.click();
          // return csvString;
        }

        if (index === 5) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          let { data: tableData } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/locations-map-graph-mode`,
            {
              parameterGroup: "NISP Parameters",
              periodOfRecord: filterValuesGraphMode.periodOfRecord,
              recordCount: filterValuesGraphMode.recordCount,
              parameters: [
                739, 23, 744, 721, 5, 98, 112, 6, 25, 1, 9, 102, 139, 10, 11,
                104, 125, 53, 21, 20, 62, 110, 13, 105, 137, 58, 32, 719, 86,
                723, 34, 37, 19, 22, 16, 722, 33, 17, 729, 30,
              ],
              locations: [
                20, 609, 783, 773, 768, 777, 776, 775, 774, 769, 771, 767, 638,
                641, 799, 647, 69, 19, 36, 37, 77, 67, 800, 65, 23, 21, 68, 70,
                78, 613, 754, 25, 24, 693, 724, 752, 73, 766, 759, 74, 801, 722,
                26, 723,
              ],
            },
            { headers }
          );

          const tableDataCsvString = [
            [
              `"Results for NISP parameters: Ammonia as N, Dissolved, Ammonia as N, Total, Ammonia as NH3, Total, Ammonia as NH4, Total, Arsenic, Dissolved, Arsenic, TD, Arsenic, Total, Arsenic, Total Recoverable, E. Coli, Flow, Iron, Dissolved, Iron, TD, Iron, Total, Iron, Total Recoverable, Manganese, Dissolved, Manganese, TD, Manganese, Total, Manganese, Total Recoverable, Nitrate as N, Nitrite as N, Orthophosphate, Total, Particulate Nitrogen, Selenium, Dissolved, Selenium, TD, Selenium, Total, Selenium, Total Recoverable, Soluble Reactive Phosphorus, Total Dissolved Kjeldahl Nitrogen as N, Total Dissolved N, Total Dissolved Nitrogen as N, Total Dissolved Phosphorus, Total Inorganic Nitrogen, Total Kjeldahl Nitrogen as N, Total Nitrate + Nitrite as N, Total Nitrogen, Total Nitrogen as NO3, Total Particulate Phosphorus, Total Phosphorus, Total Phosphorus as PO4, Water Temperature"`,
            ],
            [
              `"Results for NISP locations: 000027, 5309, 022FERN, 055WPCF, 145FSPUR, 215CHC, 225SGAGE, 2D-ARC3, 2D-GRE4, 325PFOS, 350LCR5, AG-EAS, AG-WES, CO0020320-D/S, CO0020320-EFF, CO0020478-D/S, CO0048860-EFF, CO0048860-U/S, ED-PRU, ED-PRU2, FC-0.8, GREELEYEFF, PR-16, PR-19.6, PR-2.9, PR-32.7, PR-35.3, PR-38.2, PR-4.9, PR-5.6, PR-7.5, PR-BGW, PR-TGW, TMDL-CP06, TMDL-CP09, TMDL-CP11, TMDL-CP12, TMDL-CP13, TMDL-CP14, TMDL-CP15, TMDL-CP16, TMDL-CP17, TMDL-CP18, TMDL-CP19"`,
            ],
            [
              `"Stats & Benchmarks Data for the ${titleize(
                filterValuesGraphMode.periodOfRecord
              )} Period"`,
            ],
            [
              "Location ID",
              "Location Name",
              "Parameter",
              "Parameter Abbrev.",
              "Parameter Groups",
              "Units",
              "Count of Results (Statistics)",
              "85th or 15th percentile",
              "Benchmark Classification: 85th/15th",
              "Median",
              "Benchamrk Classification: Median",
              "Analysis Period (Statistics)",
              "From",
              "To",
              "Benchmark 0",
              "Benchmark 1",
              "Benchmark 2",
              "Benchmark 3",
              "Benchmark 4",
              "Trend (all data)",
              "Organizations",
            ],
            ...tableData.map((item) => [
              item.location_id,
              `"${item.location_name}"`,
              `"${item.parameter}"`,
              `"${item.parameter_abbrev}"`,
              `"${item.parameter_group_array.join(", ")}"`,
              item.units,
              item.recordcount,
              item.pctile85,
              item.benchmark_scale_pctile85,
              item.median,
              item.benchmark_scale_median,
              item.stats_period,
              item.por_start,
              item.por_end,
              item.bmk_line0,
              item.bmk_line1,
              item.bmk_line2,
              item.bmk_line3,
              item.bmk_line4,
              item.trend,
              `"${item.organization_name}"`,
            ]),
          ]
            .map((e) => e.join(","))
            .join("\n");

          const a = document.createElement("a");
          a.href =
            "data:attachment/csv," + encodeURIComponent(tableDataCsvString);
          a.target = "_blank";
          a.download = `NISP Stats & Benchmarks Data for the ${titleize(
            filterValuesGraphMode.periodOfRecord
          )} Period.csv`;
          document.body.appendChild(a);
          a.click();
        }

        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };

  return {
    filterValuesGraphMode,
    periodOfRecords,
    analysisTypes,
    parameterGroups,
    parameters,
    handleFilterValuesGraphMode,
    onSelectAllParameters,
    onSelectNoneParameters,
    onSelectAllParameterGroups,
    onSelectNoneParameterGroups,
    handleGraphModeClick,
    hasGraphDataLoaded,
    analyticsResults,
    timeSeriesResults,
    isTimeSeriesResultsLoading,
    getHexColorForScore,
    isAnalyticsTableDataLoading,
    legendVisible,
    setLegendVisible,
    graphModeBenchmarkColors,
    handleGraphModeLayersToggleClick,
    graphModeLayersVisible,
    graphModePopupVisible,
    setGraphModePopupVisible,
    inputValue,
    setInputValue,
    handleExportClick,
    isExportLoading,
  };
};

export default useGraphMode;
