import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { convertUTCDateToLocalDate } from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Chip as MuiChip,
  Divider as MuiDivider,
  FormControl,
  Grid as MuiGrid,
  isWidthUp,
  TextField,
  Typography as MuiTypography,
  withWidth,
  Breadcrumbs as MuiBreadcrumbs,
  Paper,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useApp } from "../../../AppProvider";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../../../components/Loader";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import SplitButtonQueryAndDownload from "../../../components/SplitButtonQueryAndDownload";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 790;
  background-color: ${(props) => props.rgbcolor};
`;

// const MapContainer = styled.div`
//   height: 694px;
//   width: 100%;
// `;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const QueryAndDownload = ({ width }) => {
  const { doToast, currentUser } = useApp();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const initFilterValues = {
    streamSegments: [],
    parameterGroups: [],
    parameters: [],
    locationTypes: [],
    locations: [],
    startDate: null,
    endDate: new Date(),
    uuid: null,
    uuidOrDefault: null,
    currentUserFilters: {},
    splitButtonOptions: [],
    recordCount: "",
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const [userSelections] = useFetchData("download-user-filters", [], false);

  // const { data: userSelections } = useQuery(
  //   [`download-user-filters/${currentUser?.sub}`, currentUser],
  //   async () => {
  //     if (currentUser?.sub) {
  //       try {
  //         const { data } = await axios.get(
  //           `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters/${currentUser?.sub}`
  //         );
  //         return data ?? {};
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //     return {};
  //   },
  //   {
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    if (currentUser) {
      handleFilterValues("uuid", currentUser.sub);
    }
  }, [currentUser]);

  const [parameterGroups] = useFetchData("lookup-parameter-groups", [], false);
  const [locationTypes] = useFetchData("lookup-location-types", [], false);
  const [streamSegments] = useFetchData("lookup-stream-segments", [], false);

  useEffect(() => {
    if (userSelections?.length && filterValues?.uuid) {
      let filters;
      let uuid;
      if (userSelections.find((user) => user.user_uuid === filterValues.uuid)) {
        filters = userSelections.find(
          (user) => user.user_uuid === filterValues.uuid
        );
        uuid = filterValues.uuid;
      } else {
        filters = userSelections.find((user) => user.user_uuid === "public");
        uuid = "public";
      }

      setFilterValues((prevState) => {
        return {
          ...prevState,
          startDate: convertUTCDateToLocalDate(new Date(filters.start_date)),
          // endDate: convertUTCDateToLocalDate(new Date(filters.end_date)),
        };
      });

      handleFilterValues("currentUserFilters", filters);
      handleFilterValues(
        "splitButtonOptions",
        userSelections.filter((filter) => filter.quickset)
      );
      handleFilterValues("uuidOrDefault", uuid);
    }
  }, [userSelections, filterValues.uuid]); //eslint-disable-line

  const { data: recordCount } = useQuery(
    ["download-user-filters-recordcounts", filterValues.uuidOrDefault],
    async () => {
      if (filterValues.uuidOrDefault) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters-recordcounts`,
            {
              uuid: filterValues.uuidOrDefault,
            }
          );
          return data[0]?.sel_recordcount ?? 0;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (recordCount) {
      handleFilterValues("recordCount", recordCount);
    }
  }, [recordCount]);

  const [hasFiltersLoaded, setHasFiltersLoaded] = useState(false);
  useEffect(() => {
    if (
      parameterGroups.length &&
      locationTypes.length &&
      streamSegments.length
    ) {
      onFilterSelectAll(
        "parameterGroups",
        parameterGroups,
        "parameter_group_ndx"
      );
      onFilterSelectAll("locationTypes", locationTypes, "location_type_ndx");
      onFilterSelectAll("streamSegments", streamSegments, "stream_segment");
      setHasFiltersLoaded(true);
    }
  }, [parameterGroups, locationTypes, streamSegments]); //eslint-disable-line

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters, isFetching: isFetchingParameters } = useQuery(
    [
      "lookup-parameters-with-groups",
      filterValues.parameterGroups,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/lookup-parameters-with-groups`,
            {
              parameterGroups: filterValues.parameterGroups,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const cleanFilter = (filter, filterOptions, filterField) => {
    return filterOptions.filter((x) => {
      if (filter.find((item) => item[filterField] === x[filterField])) {
        return x;
      } else return null;
    });
  };

  useEffect(() => {
    if (
      parameters?.length &&
      !hasParametersLoaded &&
      filterValues.currentUserFilters
    ) {
      setHasParametersLoaded(true);

      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: parameters.filter((p) =>
            filterValues.currentUserFilters.parameters.includes(p.parameter_ndx)
          ),
        };
      });
    }
  }, [parameters, userSelections, filterValues.uuid]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      handleFilterValues(
        "parameters",
        cleanFilter(filterValues.parameters, parameters, "parameter_ndx")
      );
    }
  }, [parameters]); //eslint-disable-line

  const [hasLocationsLoaded, setHasLocationsLoaded] = useState(false);
  const { data: locations, isFetching: isFetchingLocations } = useQuery(
    [
      "lookup-locations-with-types-and-segments",
      filterValues.locationTypes,
      filterValues.streamSegments,
      hasFiltersLoaded,
    ],
    async () => {
      if (hasFiltersLoaded) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/lookup-locations-with-types-and-segments`,
            {
              locationTypes: filterValues.locationTypes,
              streamSegments: filterValues.streamSegments,
            }
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      locations?.length &&
      !hasLocationsLoaded &&
      filterValues.currentUserFilters
    ) {
      setHasLocationsLoaded(true);
      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: locations.filter((p) =>
            filterValues.currentUserFilters.locations.includes(p.location_ndx)
          ),
        };
      });
    }
  }, [locations, userSelections, filterValues.uuid]); //eslint-disable-line

  useEffect(() => {
    if (hasLocationsLoaded) {
      handleFilterValues(
        "locations",
        cleanFilter(filterValues.locations, locations, "location_ndx")
      );
    }
  }, [locations]); //eslint-disable-line

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  // const getFilterNameByIndex = (
  //   name,
  //   filterOptions,
  //   filterField,
  //   filterIndex
  // ) => {
  //   let filter = filterOptions?.find((x) => x[filterIndex] === name);
  //   return filter[filterField];
  // };

  const handleFilterValues = (name, value) => {
    if (["parameterGroups", "locationTypes", "streamSegments"].includes(name)) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      //dates
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmitFilters = () => {
    setIsSubmitLoading(true);
    return (async () => {
      try {
        const user = currentUser?.sub || "public";
        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters`,
          {
            user_uuid: user,
            parameters: filterValues.parameters.map(
              (parameter) => parameter.parameter_ndx
            ),
            parameter_groups: filterValues.parameterGroups,
            locations: filterValues.locations.map(
              (location) => location.location_ndx
            ),
            location_types: filterValues.locationTypes,
            stream_segments: filterValues.streamSegments,
            start_date: filterValues.startDate,
            end_date: filterValues.endDate,
          }
        );

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/download-user-filters-recordcounts`,
            {
              uuid: user,
            }
          );
          handleFilterValues("recordCount", data[0]?.sel_recordcount ?? 0);
        } catch (err) {
          console.error(err);
        }

        doToast("success", "New filters were applied to the database");
        setIsSubmitLoading(false);
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
        setIsSubmitLoading(false);
      }
    })();
  };

  const [isExportLoading, setIsExportLoading] = useState(false);
  const handleExportClick = () => {
    setIsExportLoading(true);
    async function send() {
      try {
        let { data: timeseriesData } = await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/download-user-selected-data`,
          {
            uuid: currentUser?.sub ?? "public",
          }
        );

        const timeseriesDataCsvString = [
          [
            `"Results for parameters: ${filterValues.parameters
              .map((parameter) => parameter.parameter)
              .join(", ")}"`,
          ],
          [
            `"Results for locations: ${filterValues.locations
              .map((location) => location.location_id)
              .join(", ")}"`,
          ],
          [`"Results for stream segments: ${filterValues.streamSegments}"`],
          [`"Time Series Data"`],
          [
            "Location ID",
            "Location Name",
            "Parameter",
            "Parameter Group",
            "Activity Date",
            "Data Value",
            "Units",
            "Stream Segment",
            "Location Type",
            "Organization",
            "Non-Detected",
          ],
          ...timeseriesData.map((item) => [
            `"${item.location_id ?? ""}"`,
            `"${item.location_name ?? ""}"`,
            `"${item.parameter ?? ""}"`,
            `"${item.parameter_group ?? ""}"`,
            `"${item.collect_date ?? ""}"`,
            `"${item.result ?? ""}"`,
            `"${item.units ?? ""}"`,
            `"${item.stream_segment ?? ""}"`,
            `"${item.location_type ?? ""}"`,
            `"${item.organization ?? ""}"`,
            `"${item.nondetect ?? ""}"`,
          ]),
        ]
          .map((e) => e.join(","))
          .join("\n");

        const a = document.createElement("a");
        a.href =
          "data:attachment/csv," + encodeURIComponent(timeseriesDataCsvString);
        a.target = "_blank";
        a.download = `Time Series Data.csv`;
        document.body.appendChild(a);
        a.click();
        // return csvString;

        setIsExportLoading(false);
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
          setIsExportLoading(false);
        } else {
          console.error(err);
          setIsExportLoading(false);
        }
      }
    }
    send();
  };
  const [
    loadingQuickSetFilterForLocations,
    setLoadingQuickSetFilterForLocations,
  ] = useState(null);
  const [
    loadingQuickSetFilterForParameters,
    setLoadingQuickSetFilterForParameters,
  ] = useState(null);
  const handleSplitButtonClick = () => {
    setLoadingQuickSetFilterForLocations(true);
    setLoadingQuickSetFilterForParameters(true);
    setFilterValues((prevState) => {
      return {
        ...prevState,
        parameterGroups: parameterGroups.map((x) => x["parameter_group_ndx"]),
        locationTypes: locationTypes.map((x) => x["location_type_ndx"]),
        streamSegments: streamSegments.map((x) => x["stream_segment"]),
        startDate: convertUTCDateToLocalDate(
          new Date(filterValues.splitButtonOptions[selectedIndex].start_date)
        ),
        // endDate: convertUTCDateToLocalDate(
        //   new Date(filterValues.splitButtonOptions[selectedIndex].end_date)
        // ),
      };
    });
  };

  useEffect(() => {
    if (loadingQuickSetFilterForLocations) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          locations: locations.filter((p) =>
            filterValues.splitButtonOptions[selectedIndex].locations.includes(
              p.location_ndx
            )
          ),
        };
      });
      setLoadingQuickSetFilterForLocations(false);
    }
  }, [
    loadingQuickSetFilterForLocations,
    locations,
    userSelections,
    selectedIndex,
    filterValues.splitButtonOptions,
  ]);

  useEffect(() => {
    if (loadingQuickSetFilterForParameters) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: parameters.filter((p) =>
            filterValues.splitButtonOptions[selectedIndex].parameters.includes(
              p.parameter_ndx
            )
          ),
        };
      });
      setLoadingQuickSetFilterForParameters(false);
    }
  }, [
    loadingQuickSetFilterForParameters,
    parameters,
    userSelections,
    selectedIndex,
    filterValues.splitButtonOptions,
  ]);

  useEffect(() => {
    if (
      loadingQuickSetFilterForParameters === false &&
      loadingQuickSetFilterForLocations === false
    ) {
      handleSubmitFilters();
    }
  }, [loadingQuickSetFilterForLocations, loadingQuickSetFilterForParameters]); //eslint-disable-line

  return (
    <>
      <Helmet title="Query & Download" />
      <Typography variant="h3" gutterBottom display="inline">
        Query & Download
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Query & Download</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        {/*  {locations && (*/}
        {/*    <Grid item xs={12} md={12} lg={12} xl={5}>*/}
        {/*      <Accordion defaultExpanded>*/}
        {/*        <AccordionSummary*/}
        {/*          expandIcon={<ExpandMoreIcon />}*/}
        {/*          aria-controls="map"*/}
        {/*          id="map"*/}
        {/*        >*/}
        {/*          <Typography variant="h4" ml={2}>*/}
        {/*            Map*/}
        {/*          </Typography>*/}
        {/*        </AccordionSummary>*/}
        {/*        <AccordionDetails>*/}
        {/*          <MapContainer>*/}
        {/*            <QueryAndDownloadMap*/}
        {/*              locations={filterValues.locations.map(*/}
        {/*                (location) => location.location_ndx*/}
        {/*              )}*/}
        {/*            />*/}
        {/*          </MapContainer>*/}
        {/*        </AccordionDetails>*/}
        {/*      </Accordion>*/}
        {/*    </Grid>*/}
        {/*  )}*/}

        {hasParametersLoaded && hasLocationsLoaded ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "694px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Location Filter Groups
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MultiSelect
                            size="small"
                            fullWidth
                            data={locationTypes}
                            valueField="location_type_ndx"
                            displayField="location_type_desc"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilterValues("locationTypes", e.target);
                              }
                            }}
                            value={filterValues.locationTypes}
                            name="locationTypes"
                            label="Location Types"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            width="264px"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              size="small"
                              fullWidth
                              data={streamSegments}
                              valueField="stream_segment"
                              displayField="stream_segment"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "streamSegments",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.streamSegments}
                              name="streamSegments"
                              label="Stream Segments"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              width="264px"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Locations
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!locations.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      locations: locations,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!locations.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("locations")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={3}>
                          <Autocomplete
                            loading={isFetchingLocations}
                            size="small"
                            limitTags={3}
                            multiple
                            disableCloseOnSelect
                            id="locations"
                            name="locations"
                            options={locations}
                            value={cleanFilter(
                              filterValues.locations,
                              locations,
                              "location_ndx"
                            )}
                            getOptionLabel={(option) => {
                              return option.location_descrip;
                            }}
                            // getOptionSelected={(option, value) =>
                            //   option.value === value.value
                            // }
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              handleFilterValues("locations", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                color="primary"
                                variant="outlined"
                                label="Locations"
                              />
                            )}
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Groups Filter
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              size="small"
                              fullWidth
                              data={parameterGroups}
                              valueField="parameter_group_ndx"
                              displayField="parameter_group"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterGroups",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterGroups}
                              name="parameterGroups"
                              label="Parameter Groups"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              width="264px"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  setFilterValues((prevState) => {
                                    return {
                                      ...prevState,
                                      parameters: parameters,
                                    };
                                  })
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={3}>
                          <Autocomplete
                            loading={isFetchingParameters}
                            size="small"
                            limitTags={3}
                            multiple
                            disableCloseOnSelect
                            id="parameters"
                            name="parameters"
                            options={parameters}
                            value={cleanFilter(
                              filterValues.parameters,
                              parameters,
                              "parameter_ndx"
                            )}
                            getOptionLabel={(option) => {
                              return option.parameter;
                            }}
                            filterSelectedOptions
                            onChange={(event, newValue) => {
                              handleFilterValues("parameters", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                color="primary"
                                variant="outlined"
                                label="Parameters"
                              />
                            )}
                          />
                        </Box>
                      </Grid>

                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Time Period Selection
                      </Typography>
                      <Grid container>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                              marginRight: "12px",
                            }}
                          >
                            <KeyboardDatePicker
                              size="small"
                              fullWidth
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="start-date-picker"
                              label="Start Date"
                              value={filterValues.startDate}
                              onChange={(date) =>
                                handleFilterValues("startDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change start date",
                              }}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "calc(50% - 6px)",
                            }}
                          >
                            <KeyboardDatePicker
                              size="small"
                              autoOk
                              inputVariant="outlined"
                              disableToolbar
                              format="MM/dd/yyyy"
                              id="end-date-picker"
                              label="End Date"
                              value={filterValues.endDate}
                              onChange={(date) =>
                                handleFilterValues("endDate", date)
                              }
                              InputAdornmentProps={{
                                "aria-label": "change end date",
                              }}
                            />
                          </FormControl>
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Typography
                        color="primary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Quick Set & Filters Submission
                      </Typography>

                      {filterValues.splitButtonOptions.length > 0 && (
                        <Box style={{ width: "100%" }}>
                          <SplitButtonQueryAndDownload
                            options={filterValues?.splitButtonOptions}
                            handleExportClick={handleSplitButtonClick}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                          />
                        </Box>
                      )}

                      <Box mt={2} style={{ width: "100%" }}>
                        {isSubmitLoading ? (
                          <Loader />
                        ) : (
                          <Button
                            disabled={
                              !filterValues.parameters.length ||
                              !filterValues.locations.length ||
                              !filterValues.streamSegments.length ||
                              !filterValues.startDate ||
                              !filterValues.endDate ||
                              isExportLoading
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={() => handleSubmitFilters()}
                          >
                            Submit Filters
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="200px"
          >
            <Loader />
          </Box>
        )}
        <Grid item xs={12}>
          <Paper style={{ padding: "10px" }}>
            <Typography variant="h4" ml={2}>
              Download Records
            </Typography>
            <Box ml={3} mr={3} mb={3}>
              <Grid container>
                <Grid item xs={9} style={{ display: "flex" }}>
                  <Box display="flex" alignItems="center" justifyContent="left">
                    <Typography mr={2}>
                      Recordcount for Selected Data:
                    </Typography>
                    <Typography variant="h6" align="left">
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={filterValues.recordCount || 0}
                      />
                    </Typography>
                    {filterValues?.recordCount > 110000 && (
                      <Typography variant="subtitle2" color="error" ml={2}>
                        Maximum records for download is <strong>110,000</strong>
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  {isExportLoading ? (
                    <Loader />
                  ) : (
                    <Button
                      disabled={
                        filterValues?.recordCount === "" ||
                        filterValues?.recordCount === 0 ||
                        filterValues?.recordCount > 110000 ||
                        isSubmitLoading
                      }
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleExportClick}
                    >
                      <Typography
                        variant="h6"
                        style={{ textTransform: "uppercase" }}
                      >
                        Download
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(QueryAndDownload);
