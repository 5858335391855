import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import {
  downloadCsv,
  getUniqueListBy,
  groupByValueArray,
} from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Icon,
  isWidthUp,
  MenuItem,
  Popover,
  TextField,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { spacing } from "@material-ui/system";
import WaterQualityReportCardMap from "../../../components/map/WaterQualityReportCardMap";
import { Helmet } from "react-helmet-async";
import useFetchData from "../../../hooks/useFetchData";
import { MultiSelect } from "@lrewater/lre-react";
import Button from "@material-ui/core/Button";
import Chips from "../../../components/Chips";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Panel from "../../../components/panels/Panel";
import Table from "../../../components/Table";
import DownloadIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import { useReactToPrint } from "react-to-print";
import { useAuth0 } from "@auth0/auth0-react";
import BenchmarkPopoverReportCard from "../../publicMap/components/BenchmarkPopoverReportCard";
import Info from "@material-ui/icons/Info";

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);

const MapContainer = styled.div`
  height: 646px;
  width: 100%;
`;

const FiltersContainer = styled.div`
  min-height: ${({ height }) => height};
  height: 100%;
  width: 100%;
`;

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2196F3",
      },
    },
  },
})(TextField);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: calc(100%);
  width: 100%;
`;

const Typography = styled(MuiTypography)(spacing);

const WaterQualityReportCard = ({ width }) => {
  const useRowStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
      borderRadius: 4,
    },
    paper: {
      backgroundColor: "transparent",
    },
  }));

  function ParametersColumn({ rowData }) {
    const classes = useRowStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    return (
      <>
        <div
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Typography>{rowData.parameter.parameter}</Typography>
          <Info color="secondary" style={{ marginLeft: "10px" }} />
        </div>

        <Popover
          disableAutoFocus
          disableEnforceFocus
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          // disableRestoreFocus
        >
          <BenchmarkPopoverReportCard
            data={rowData.parameter}
            lowIsBad={rowData.parameter.lowIsBad}
          />
        </Popover>
      </>
    );
  }

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const saveRef = useRef(null);
  const initFilterValues = {
    periodOfRecord: "full",
    parameterGroups: ["NISP Parameters"],
    parameters: ["E. Coli"],
    streamSegment: "COSPCP12a_A",
  };

  const [filterValues, setFilterValues] = useState(initFilterValues);

  const periodOfRecords = [
    {
      value: "short",
      label: "Recent",
    },
    {
      value: "medium",
      label: "Last 10 Years",
    },
    {
      value: "full",
      label: "Full Period",
    },
  ];

  const streamSegments = [
    {
      value: "COSPCP12a_A",
      label: "12a",
    },
    {
      value: "COSPCP12b_A",
      label: "12b",
    },
  ];

  const onFilterSelectAll = (filterType, filterOptions, filterField) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: filterOptions.map((x) => x[filterField]),
      };
    });
  };

  const onFilterSelectNone = (filterType) => {
    setFilterValues((prevState) => {
      return {
        ...prevState,
        [filterType]: [],
      };
    });
  };

  const getFilterIndexByName = (
    name,
    filterOptions,
    filterField,
    filterIndex
  ) => {
    let filter = filterOptions?.find((x) => x[filterField] === name);
    return filter[filterIndex];
  };

  const cleanFilter = (filter, filterOptions, filterField) => {
    const newParams = [];
    filterOptions.forEach((x) => {
      if (filter.indexOf(x[filterField]) !== -1) {
        newParams.push(x[filterField]);
      }
    });
    return newParams;
  };

  const [parameterGroups, isParameterGroupsLoading] = useFetchData(
    "list-parameter-groups-graph-mode",
    [],
    false
  );

  const [hasParametersLoaded, setHasParametersLoaded] = useState(false);
  const { data: parameters } = useQuery(
    [
      "list-parameters-graph-mode",
      filterValues.parameterGroups,
      filterValues.periodOfRecord,
      isParameterGroupsLoading,
    ],
    async () => {
      if (!isParameterGroupsLoading) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/list-parameters-graph-mode`,
            {
              parameterGroups: filterValues.parameterGroups.map(
                (parameterGroup) =>
                  getFilterIndexByName(
                    parameterGroup,
                    parameterGroups,
                    "parameter_group_name",
                    "parameter_group_ndx"
                  )
              ),
              periodOfRecord: filterValues.periodOfRecord,
            }
          );
          return getUniqueListBy(data, "parameter_ndx");
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (parameters?.length && !hasParametersLoaded) {
      setHasParametersLoaded(true);
      onFilterSelectAll("parameters", parameters, "parameter_abbrev");
    }
  }, [parameters]); //eslint-disable-line

  useEffect(() => {
    if (hasParametersLoaded) {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          parameters: cleanFilter(
            filterValues.parameters,
            parameters,
            "parameter_abbrev"
          ),
        };
      });
    }
  }, [parameters]); //eslint-disable-line

  const handleFilterValues = (name, value) => {
    if (["parameters"].includes(name)) {
      setFilterValues((prevState) => {
        const existingVals = [...prevState[name]];
        const existingIndex = existingVals.indexOf(value);
        existingIndex > -1
          ? existingVals.splice(existingIndex, 1)
          : existingVals.push(value);

        return {
          ...prevState,
          [name]: existingVals,
        };
      });
    } else if (["parameterGroups"].includes(name)) {
      setFilterValues((prevState) => {
        let newValues = { ...prevState };
        newValues[name] = value.value;
        return newValues;
      });
    } else {
      setFilterValues((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
    }
  };

  const cellColorBackground = (value) => {
    if (value === null) {
      return "black";
    } else if (value === 4) {
      return "#FF0000";
    } else if (value === 3) {
      return "#FFA500";
    } else if (value === 2) {
      return "#FFFF00";
    } else if (value === 1) {
      return "#3CB371";
    } else if (value === 0) {
      return "#AFEEEE";
    } else if (value === -1) {
      return "#6495ED";
    } else return "purple";
  };

  const cellColor = (value) => {
    if ([2, 0].includes(value)) {
      return "black";
    } else return "white";
  };

  const setTrendIcon = (trend) => {
    const val = trend ? trend.toLowerCase() : "";
    if (val === "no trend") {
      return `swap_vert`;
    } else if (val.includes(`increasing`)) {
      return `arrow_upward`;
    } else if (val.includes(`decreasing`)) {
      return `arrow_downward`;
    } else if (val === "stable") {
      return `trending_flat`;
    } else {
      return "not_interested";
    }
  };

  const [columns, setColumns] = useState([]);
  const { data, isFetching, error } = useQuery(
    [
      "locations-map-graph-mode",
      hasParametersLoaded,
      filterValues.parameters,
      filterValues.streamSegment,
      filterValues.periodOfRecord,
    ],
    async () => {
      if (hasParametersLoaded) {
        setColumns([]);
        let headers = {};
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          headers = { Authorization: `Bearer ${token}` };
        }
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/locations-map-graph-mode`,
            {
              parameters: filterValues?.parameters
                ? filterValues.parameters.map((parameter) =>
                    getFilterIndexByName(
                      parameter,
                      parameters,
                      "parameter_abbrev",
                      "parameter_ndx"
                    )
                  )
                : [],
              periodOfRecord: filterValues.periodOfRecord,
              streamSegment: filterValues.streamSegment,
            },
            { headers }
          );
          const groupedData = groupByValueArray(
            data.filter(
              (item) => item.parameter_group_name !== "NISP Parameters"
            ),
            "parameter"
          );
          const buildColumns = [
            {
              title: "Parameters",
              field: "parameter",
              cellStyle: {
                whiteSpace: "nowrap",
                padding: "3px 3px 3px 3px",
                position: "sticky",
                left: 0,
                background: "white",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              headerStyle: {
                padding: "3px 3px 3px 3px",
                fontWeight: 900,
                position: "sticky",
                left: 0,
                background: "white",
                zIndex: 11,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              position: "sticky",
              left: 0,
              background: "white",
              defaultSort: "asc",
              render: (rowData) => {
                return (
                  <ParametersColumn rowData={rowData} key={rowData.parameter} />
                );
              },
            },
            {
              title: "Units",
              field: "units",
              cellStyle: {
                whiteSpace: "nowrap",
                padding: "3px 3px 3px 3px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              headerStyle: {
                padding: "3px 3px 3px 3px",
                fontWeight: 900,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            },
            {
              title: "Parameter Groups",
              field: "parameterGroup",
              cellStyle: {
                whiteSpace: "nowrap",
                padding: "3px 3px 3px 3px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              headerStyle: {
                padding: "3px 3px 3px 3px",
                fontWeight: 900,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            },
          ];

          const crossTabbedData = groupedData.map((parameter) => {
            const obj = {};
            obj["parameter"] = parameter[0];
            obj["units"] = parameter[0].units;
            obj["parameterGroup"] = parameter[0].parameter_group_name;
            parameter.forEach((item) => {
              if (item?.location_id) {
                obj[item.location_id] = {
                  score85: item.benchmark_scale_pctile85,
                  scoreMedian: item.benchmark_scale_median,
                  pctileMedian: item.median,
                  pctile85: item.pctile85,
                  count: item.recordcount,
                  trend: item.trend,
                };
                if (
                  !buildColumns.find(
                    (col) =>
                      col.title ===
                      `${item.location_name} (${item.location_id})`
                  )
                ) {
                  buildColumns.push({
                    title: `${item.location_name} (${item.location_id})`,
                    field: item.location_id,
                    sort: item.report_card_sort,
                    sorting: false,
                    filtering: false,
                    render: (rowData) => {
                      return rowData[item.location_id]?.scoreMedian != null ? (
                        <div
                          style={{
                            minWidth: "100px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              85th:{" "}
                              {rowData[item.location_id]?.pctile85 ?? null}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: cellColorBackground(
                                rowData[item.location_id]?.scoreMedian ?? null
                              ),
                              color: cellColor(
                                rowData[item.location_id]?.scoreMedian ?? null
                              ),
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              Med:{" "}
                              {rowData[item.location_id]?.pctileMedian ?? null}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              Count: {rowData[item.location_id]?.count ?? null}
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography style={{ fontWeight: 600 }}>
                              Trend:
                            </Typography>
                            <Icon style={{ marginLeft: "5px" }}>
                              {setTrendIcon(
                                rowData[item.location_id]?.trend ?? null
                              )}
                            </Icon>
                          </div>
                        </div>
                      ) : (
                        <Typography variant="h4">N/A</Typography>
                      );
                    },

                    cellStyle: (e, rowData) => {
                      return {
                        padding: "5px",
                        backgroundColor: cellColorBackground(
                          rowData[item.location_id]?.score85 ?? null
                        ),
                        color: cellColor(
                          rowData[item.location_id]?.score85 ?? null
                        ),
                        textAlign: "center",
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent: "center",
                        // width: "100%",
                      };
                    },
                    headerStyle: {
                      padding: "3px",
                      textAlign: "center",
                      fontWeight: 900,
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  });
                }
              }
            });
            return obj;
          });
          setColumns(
            buildColumns.sort((a, b) => {
              return a.sort - b.sort;
            })
          );
          return crossTabbedData;
        } catch (err) {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handlePrintClick = useReactToPrint({
    content: () => saveRef.current,
  });

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Helmet title="Time Series Comparisons" />
      <Typography variant="h3" gutterBottom display="inline">
        Water Quality Report Card
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="map"
              id="map"
            >
              <Typography variant="h4" ml={2}>
                Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapContainer>
                <WaterQualityReportCardMap />
              </MapContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {hasParametersLoaded && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="time-series"
                id="time-series"
              >
                <Typography variant="h4" ml={2}>
                  Filter Controls
                </Typography>
              </AccordionSummary>
              <Box ml={3} mr={3} mb={isWidthUp("xl", width) ? 0 : 3}>
                <AccordionDetails>
                  <FiltersContainer
                    height={isWidthUp("xl", width) ? "646px" : "100%"}
                    style={{ display: "flex" }}
                  >
                    <Grid container>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameter Filter Groups
                      </Typography>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MultiSelect
                              fullWidth
                              data={parameterGroups}
                              valueField="parameter_group_name"
                              displayField="parameter_group_name"
                              onChange={(e) => {
                                if (e.target.value.includes("all/none")) {
                                  return null;
                                } else {
                                  handleFilterValues(
                                    "parameterGroups",
                                    e.target
                                  );
                                }
                              }}
                              value={filterValues.parameterGroups}
                              name="parameterGroups"
                              label="Parameter Groups"
                              variant="outlined"
                              fillColor="primary"
                              outlineColor="primary"
                              labelColor="primary"
                              margin="normal"
                              width="264px"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Parameters
                      </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters?.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() =>
                                  onFilterSelectAll(
                                    "parameters",
                                    parameters,
                                    "parameter_abbrev"
                                  )
                                }
                              >
                                + Select All
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={!parameters?.length}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                onClick={() => onFilterSelectNone("parameters")}
                              >
                                - Select None
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        {parameters?.length === 0 && (
                          <Typography mt={3} align="center" variant="subtitle2">
                            ----- None Available -----
                          </Typography>
                        )}
                        <Chips
                          data={parameters}
                          valueField="parameter_abbrev"
                          displayField="parameter_abbrev"
                          handleChipClick={(e) =>
                            handleFilterValues(
                              "parameters",
                              e.target.textContent
                            )
                          }
                          type="parameter-type"
                          activeChips={filterValues.parameters}
                          tooltipField1="parameter_name"
                          tooltipField2="units_desc"
                        />
                      </Grid>
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        gutterBottom
                      >
                        Locations & Time Periods
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            select
                            color="primary"
                            // size="small"
                            label="Stream Segment"
                            value={filterValues.streamSegment}
                            onChange={(e) =>
                              handleFilterValues(
                                "streamSegment",
                                e.target.value
                              )
                            }
                          >
                            {streamSegments.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            select
                            color="primary"
                            // size="small"
                            label="Period of Record"
                            value={filterValues.periodOfRecord}
                            onChange={(e) =>
                              handleFilterValues(
                                "periodOfRecord",
                                e.target.value
                              )
                            }
                          >
                            {periodOfRecords.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </CustomTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FiltersContainer>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Accordion defaultExpanded ref={saveRef}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="table-content"
              id="table-header"
            >
              <Typography variant="h4" ml={2}>
                Water Quality Report Card
              </Typography>
            </AccordionSummary>
            <Panel>
              <AccordionDetails>
                <TableWrapper>
                  <Table
                    search={false}
                    filtering={true}
                    isLoading={isFetching}
                    pageSize={60}
                    label="Data Points Hydrologic Health"
                    columns={columns}
                    data={data}
                    height="100%"
                    sortArrow={<React.Fragment />}
                    exportButton={false}
                    copyIcon={false}
                    actions={[
                      {
                        icon: DownloadIcon,
                        tooltip: "Export as CSV",
                        isFreeAction: true,
                        onClick: () => {
                          const headerRow = columns.map((col) => {
                            return col.title;
                          });
                          const dataRows = data.map(({ tableData, ...row }) => {
                            return columns.map((col) => {
                              if (typeof row[col.field] !== "object") {
                                return `"${row[col.field] ?? ""}"`;
                              } else {
                                if (col.title === "Parameters") {
                                  return JSON.stringify(
                                    row[col.field].parameter
                                  )
                                    .replaceAll(",", "; ")
                                    .replace(/["{}]/g, "");
                                } else {
                                  return JSON.stringify(row[col.field])
                                    .replaceAll(",", "; ")
                                    .replace(/["{}]/g, "");
                                }
                              }
                            });
                          });

                          const csvContent = [headerRow, ...dataRows]
                            .map((e) => e.join(","))
                            .join("\n");
                          const csvFileName = "Water Quality Report Card";

                          downloadCsv(csvContent, csvFileName);
                        },
                      },
                      {
                        icon: SaveIcon,
                        tooltip: "Save or Print as PDF",
                        isFreeAction: true,
                        onClick: () => {
                          handlePrintClick();
                        },
                      },
                    ]}
                  />
                </TableWrapper>
              </AccordionDetails>
            </Panel>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default withWidth()(WaterQualityReportCard);
